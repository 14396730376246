import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';

import { IstatGuestsReportStoreEffects } from './effects';
import {
  IstatGuestReportReducer,
  istatGuestsReportReducerFactory,
} from './reducer';
import { State } from './state';

const REDUCER_TOKEN = new InjectionToken<ActionReducer<Map<string, State>>>(
  'Istat guest report Reducer',
);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('istatGuestsReport', REDUCER_TOKEN),
    EffectsModule.forFeature([IstatGuestsReportStoreEffects]),
  ],
  providers: [
    IstatGuestReportReducer,
    {
      provide: REDUCER_TOKEN,
      deps: [IstatGuestReportReducer],
      useFactory: istatGuestsReportReducerFactory,
    },
  ],
})
export class IstatGuestsReportStoreModule {}
