import { Injectable } from '@angular/core';
import { endOfMonth, isAfter, isEqual, startOfMonth } from 'date-fns';

import { ByDateHelpers, formatDate, removeTime } from '../../../../helpers';
import { IstatPageAlerts } from '../../../../models';
import { BuildAlertData } from '../../../../models/objects/build-alert-data';

import { AgencyService } from './agency.service';
import { first, last } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AstatService extends AgencyService {
  _agencyStatus: Partial<BuildAlertData>;

  showSendButton = true;

  showExportButton = false;

  showNextButton = false;

  showCloseButton = false;

  showPropertyClousureButton = false;

  get agencyStatus() {
    return this._agencyStatus;
  }

  set agencyStatus(value: Partial<BuildAlertData>) {
    this._agencyStatus = value;
    this.setButtonToShow();
  }

  get isExportDisabled(): boolean {
    return false;
  }

  get isNextDisabled(): boolean {
    return (
      this.isClosedMonth ||
      this.todayIsEqualReference ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get isSendDisabled(): boolean {
    return (
      this.isBetweenAnClousurePeriod ||
      this.isClosedMonth ||
      this.todayIsEqualReference ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get isCloseDisabled(): boolean {
    return (
      this.showArrivalsOrChangesToSentAlert ||
      this.showCanSendYesterdayCardsAlert ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get areReferenceDateAndCurrentDayDivergent() {
    if (!this.agencyStatus) {
      return;
    }
    const { currentDay, referenceDate } = this.agencyStatus;
    return !isEqual(referenceDate, currentDay);
  }

  get showSendingLimitYesterdayAlert() {
    if (!this.agencyStatus) {
      return;
    }

    const today = removeTime(new Date());

    return isEqual(this.agencyStatus?.referenceDate, today);
  }

  setButtonToShow() {
    if (!this.agencyStatus) {
      return;
    }

    const {
      referenceDate,
      agencyDetails: {
        customs: { property_closed_date_range },
      },
    } = this.agencyStatus;

    const range = {
      start: new Date(first(property_closed_date_range)),
      end: new Date(last(property_closed_date_range)),
    };

    if (ByDateHelpers.isBetween(referenceDate, range, [true, true])) {
      this.showPropertyClousureButton = true;
      this.showSendButton = false;
      return;
    }

    this.showPropertyClousureButton = false;

    this.showSendButton = true;
  }

  buildAlerts(): IstatPageAlerts[] {
    let alerts: IstatPageAlerts[] = [];

    alerts = [
      ...alerts,

      {
        messages: ['istat_agency_alerts.send_close_months_mandatory'],
        priority: 5,
        type: 'info',
        params: {
          agencyName: this.agencyStatus?.agencyDetails?.agency?.name,
        },
      },
    ];

    if (this.isBetweenAnClousurePeriod) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.property_closed_send_message'],
          params: { action: 'to_send' },
          priority: 2,
          type: 'info',
        },
      ];
    }

    if (this.isClosedMonth) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.month_closed_message'],
          params: { action: 'to_send' },
          priority: 3,
          type: 'info',
        },
      ];
    }

    if (this.todayIsEqualReference) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.action_limit_yesterday'],
          params: { action: 'to_send' },
          priority: 4,
          type: 'info',
        },
      ];
    }

    if (this.areReferenceDateAndCurrentDayDivergent) {
      alerts = [
        ...alerts,
        {
          messages: [
            'istat_agency_alerts.reference_date_is_divergent_current_date',
          ],

          params: {
            current_day: formatDate(
              this.agencyStatus?.agencyDetails?.customs?.current_day,
            ),
            action_phrase: 'current_date',
          },
          priority: 5,
          type: 'info',
        },
      ];
    }

    if (
      !this.isBetweenAnClousurePeriod &&
      !this.isClosedMonth &&
      !this.todayIsEqualReference &&
      !this.areReferenceDateAndCurrentDayDivergent
    ) {
      alerts = [
        ...alerts,
        {
          messages: [
            'istat_agency_alerts.send_every_day_without_zero_movements',
          ],
          priority: 1,
          type: 'info',
          params: {
            agencyName: this.agencyStatus?.agencyDetails?.agency?.name,
          },
        },
      ];
    }

    alerts.sort(
      ({ priority: priorityA }, { priority: priorityB }) =>
        priorityB - priorityA,
    );
    if (alerts.length) {
      return [alerts[0], alerts[1]];
    }
    return [];
  }

  get showArrivalsOrChangesToSentAlert(): boolean {
    if (!this.agencyStatus) {
      return;
    }

    const { allGuestsSent, atLeastOneGuestNeedsUpdate } = this.agencyStatus;

    return !allGuestsSent || atLeastOneGuestNeedsUpdate;
  }

  get showCloseAlert() {
    if (!this.agencyStatus) {
      return;
    }

    const { allGuestsSent, atLeastOneGuestNeedsUpdate, reservationsTotal } =
      this.agencyStatus;

    return (allGuestsSent && !atLeastOneGuestNeedsUpdate) || !reservationsTotal;
  }

  get showCanSendYesterdayCardsAlert() {
    if (!this.agencyStatus) {
      return;
    }

    const { referenceDate } = this.agencyStatus;

    if (!referenceDate) {
      return;
    }

    const today = removeTime(new Date());

    return isEqual(referenceDate, today) || isAfter(referenceDate, today);
  }

  get exportActioName(): string {
    return null;
  }

  get isClosedMonth() {
    if (!this.agencyStatus) {
      return;
    }

    const {
      referenceDate,
      agencyDetails: {
        customs: { last_month_closed },
      },
    } = this.agencyStatus || {};

    const range = {
      start: startOfMonth(new Date(last_month_closed)),
      end: endOfMonth(new Date(last_month_closed)),
    };

    return ByDateHelpers.isBetween(referenceDate, range);
  }

  get todayIsEqualReference() {
    if (!this.agencyStatus) {
      return;
    }
    const { referenceDate } = this.agencyStatus;

    return isEqual(removeTime(new Date()), removeTime(referenceDate));
  }

  get isBetweenAnClousurePeriod() {
    if (!this.agencyStatus) {
      return;
    }

    const {
      referenceDate,
      agencyDetails: {
        customs: { property_closed_date_range },
      },
    } = this.agencyStatus;

    const range = {
      start: removeTime(new Date(first(property_closed_date_range))),
      end: removeTime(new Date(last(property_closed_date_range))),
    };

    return ByDateHelpers.isBetween(referenceDate, range, [true, true]);
  }
}
