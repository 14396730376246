import { Injectable } from '@angular/core';
import { isAfter, isEqual } from 'date-fns';

import { formatDate, removeTime } from '../../../../helpers';
import { IstatPageAlerts } from '../../../../models';
import { BuildAlertData } from '../../../../models/objects/build-alert-data';

import { AgencyService } from './agency.service';

@Injectable({ providedIn: 'root' })
export class OsservatorioTuristicoService extends AgencyService {
  agencyStatus: Partial<BuildAlertData>;

  showSendButton = true;

  showExportButton = false;

  showNextButton = false;

  showCloseButton = true;

  get isExportDisabled(): boolean {
    return false;
  }

  get isNextDisabled(): boolean {
    return false;
  }

  get isSendDisabled(): boolean {
    return (
      !this.showArrivalsOrChangesToSentAlert ||
      this.showCanSendYesterdayCardsAlert ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get isCloseDisabled(): boolean {
    return (
      this.showArrivalsOrChangesToSentAlert ||
      this.showCanSendYesterdayCardsAlert ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get areReferenceDateAndCurrentDayDivergent() {
    if (!this.agencyStatus) {
      return;
    }
    const { currentDay, referenceDate } = this.agencyStatus;
    return !isEqual(referenceDate, currentDay);
  }

  buildAlerts(): IstatPageAlerts[] {
    let alerts: IstatPageAlerts[] = [];

    if (this.showArrivalsOrChangesToSentAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.arrivals_or_changes_to_be_deliver'],
          priority: 1,
          type: 'info',
          params: {
            action: 'to_send',
          },
        },
      ];
    }

    if (this.showCloseAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.close_to_move_on_next_day'],
          priority: 2,
          type: 'info',
        },
      ];
    }

    if (this.showCanSendYesterdayCardsAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.action_limit_yesterday'],
          params: { action: 'to_send' },
          priority: 3,
          type: 'info',
        },
      ];
    }

    if (this.areReferenceDateAndCurrentDayDivergent) {
      alerts = [
        ...alerts,
        {
          messages: [
            'istat_agency_alerts.reference_date_is_divergent_current_date',
          ],
          params: {
            current_day: formatDate(
              this.agencyStatus?.agencyDetails?.customs?.current_day,
            ),
            action_phrase: 'current_date',
          },
          priority: 4,
          type: 'info',
        },
      ];
    }

    alerts.sort(
      ({ priority: priorityA }, { priority: priorityB }) =>
        priorityB - priorityA,
    );
    if (alerts.length) {
      return [alerts[0]];
    }
    return [];
  }

  get showArrivalsOrChangesToSentAlert(): boolean {
    if (!this.agencyStatus) {
      return;
    }

    const { allGuestsSent, atLeastOneGuestNeedsUpdate } = this.agencyStatus;

    return !allGuestsSent || atLeastOneGuestNeedsUpdate;
  }

  get showCloseAlert() {
    if (!this.agencyStatus) {
      return;
    }

    const { allGuestsSent, atLeastOneGuestNeedsUpdate, reservationsTotal } =
      this.agencyStatus;

    return (allGuestsSent && !atLeastOneGuestNeedsUpdate) || !reservationsTotal;
  }

  get showCanSendYesterdayCardsAlert() {
    if (!this.agencyStatus) {
      return;
    }

    const { referenceDate } = this.agencyStatus;

    if (!referenceDate) {
      return;
    }

    const today = removeTime(new Date());

    return isEqual(referenceDate, today) || isAfter(referenceDate, today);
  }

  get exportActioName(): string {
    return null;
  }
}
