import { Injectable } from '@angular/core';
import { isAfter, isEqual } from 'date-fns';

import { formatDate, removeTime } from '../../../../helpers';
import { IstatPageAlerts } from '../../../../models';
import { BuildAlertData } from '../../../../models/objects/build-alert-data';

import { AgencyService } from './agency.service';

@Injectable({ providedIn: 'root' })
export class RicestatService implements AgencyService {
  agencyStatus: Partial<BuildAlertData>;

  showSendButton = true;

  showExportButton = false;

  showNextButton = true;

  showCloseButton = false;

  get exportActioName(): string {
    return null;
  }

  get isExportDisabled(): boolean {
    return false;
  }

  get isNextDisabled(): boolean {
    return (
      this.showSendLimitYesterdayAlert ||
      !this.showNoReservationCheckinCheckoutAlert ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get isSendDisabled(): boolean {
    return (
      this.showNoReservationCheckinCheckoutAlert ||
      this.showSendLimitYesterdayAlert ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get isCloseDisabled(): boolean {
    return false;
  }

  buildAlerts(): IstatPageAlerts[] {
    let alerts: IstatPageAlerts[] = [];

    if (!this.showNoReservationCheckinCheckoutAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.arrivals_or_changes_to_be_deliver'],
          priority: 1,
          type: 'info',
          params: { action: 'to_send' },
        },
      ];
    }

    if (this.showNoReservationCheckinCheckoutAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.no_reservation_checkin_or_update'],
          priority: 2,
          type: 'info',
          params: {
            action_phrase: 'data_of_send',
            action: 'to_send',
          },
        },
      ];
    }

    if (this.showSendLimitYesterdayAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.action_limit_yesterday'],
          params: { action: 'to_send' },
          priority: 3,
          type: 'info',
        },
      ];
    }

    if (this.areReferenceDateAndCurrentDayDivergent) {
      alerts = [
        ...alerts,
        {
          messages: [
            'istat_agency_alerts.reference_date_is_divergent_current_date',
          ],
          params: {
            current_day: formatDate(
              this.agencyStatus?.agencyDetails?.customs?.current_day,
            ),
            action_phrase: 'data_of_send',
            action: 'to_send',
          },
          priority: 4,
          type: 'info',
        },
      ];
    }

    alerts.sort(
      ({ priority: priorityA }, { priority: priorityB }) =>
        priorityB - priorityA,
    );
    if (alerts.length) {
      return [alerts[0]];
    }
    return [];
  }

  get showNoReservationCheckinCheckoutAlert(): boolean {
    if (!this.agencyStatus) {
      return;
    }

    const { allGuestsSent, atLeastOneGuestNeedsUpdate, reservationsTotal } =
      this.agencyStatus;

    return (
      reservationsTotal === 0 || (allGuestsSent && !atLeastOneGuestNeedsUpdate)
    );
  }

  get areReferenceDateAndCurrentDayDivergent() {
    if (!this.agencyStatus) {
      return;
    }
    const { currentDay, referenceDate } = this.agencyStatus;

    return !isEqual(removeTime(referenceDate), removeTime(currentDay));
  }

  get showSendLimitYesterdayAlert() {
    if (!this.agencyStatus) {
      return;
    }

    const today = removeTime(new Date());

    const referenceDate = this.agencyStatus?.referenceDate;

    return isEqual(referenceDate, today) || isAfter(referenceDate, today);
  }
}
