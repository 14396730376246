import { IstatPageAlerts } from '../../../../models';
import { BuildAlertData } from '../../../../models/objects/build-alert-data';

export abstract class AgencyService {
  abstract agencyStatus: Partial<BuildAlertData>;

  abstract showExportButton: boolean;

  abstract showSendButton: boolean;

  abstract showNextButton: boolean;

  abstract showCloseButton: boolean;

  constructor() {}

  abstract get isExportDisabled(): boolean;

  abstract get isSendDisabled(): boolean;

  abstract get isNextDisabled(): boolean;

  abstract get isCloseDisabled(): boolean;

  abstract get exportActioName(): string;

  abstract buildAlerts(): IstatPageAlerts[];
}
