import { Injectable } from '@angular/core';
import { format, isAfter, isEqual } from 'date-fns';
import { get } from 'lodash';

import { formatDate, removeTime } from '../../../../helpers';
import { IstatPageAlerts } from '../../../../models';
import { BuildAlertData } from '../../../../models/objects/build-alert-data';

import { AgencyService } from './agency.service';

@Injectable({ providedIn: 'root' })
export class C59Service implements AgencyService {
  agencyStatus: Partial<BuildAlertData>;

  showSendButton = false;

  showExportButton = true;

  showNextButton = false;

  showCloseButton = false;

  get isSendDisabled() {
    return false;
  }

  get isCloseDisabled(): boolean {
    return false;
  }

  get isExportDisabled(): boolean {
    return (
      this.showSendingLimitYesterdayAlert ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get isNextDisabled(): boolean {
    return false;
  }

  addLastExportedFileMessage(
    alert: IstatPageAlerts,
    last_sent_day: string,
    progressive: string,
  ): IstatPageAlerts {
    return {
      ...alert,
      messages: [
        'istat_agency_alerts.last_exported_model_message',
        ...alert.messages,
      ],
      params: {
        ...alert.params,
        exportDate: format(new Date(last_sent_day), 'dd/MM/yyyy'),
        progressiveExportNumber: +progressive > 0 ? +progressive - 1 : '-',
      },
    };
  }

  buildAlerts(): IstatPageAlerts[] {
    const last_sent_day = get(
      this.agencyStatus?.agencyDetails?.customs,
      'last_sent_day',
    ) as string;

    const progressive = get(
      this.agencyStatus?.agencyDetails?.customs,
      'c59_progression',
      0,
    );

    let alerts: IstatPageAlerts[] = [];

    const messages = ['istat_agency_alerts.export_every_day'];

    alerts = [
      ...alerts,
      {
        messages,
        priority: 1,
        type: 'info',
        params: {
          agencyName: this.agencyStatus?.agencyDetails?.agency?.name,
        },
      },
    ];

    if (this.showSendingLimitYesterdayAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.action_limit_yesterday'],
          params: { action: 'export_1' },
          priority: 2,
          type: 'info',
        },
      ];
    }

    if (this.areReferenceDateAndCurrentDayDivergent) {
      alerts = [
        ...alerts,
        {
          messages: [
            'istat_agency_alerts.reference_date_is_divergent_current_date',
          ],
          params: {
            current_day: formatDate(
              this.agencyStatus?.agencyDetails?.customs?.current_day,
            ),
            action_phrase: 'data_of_export',
          },
          priority: 3,
          type: 'info',
        },
      ];
    }

    alerts.sort(
      ({ priority: priorityA }, { priority: priorityB }) =>
        priorityB - priorityA,
    );
    if (alerts.length) {
      const alert = alerts[0];

      if (last_sent_day && progressive) {
        this.addLastExportedFileMessage(alert, last_sent_day, progressive);
      }
      return [alert];
    }
    return [];
  }

  get showSendingLimitYesterdayAlert() {
    if (!this.agencyStatus) {
      return;
    }

    const today = removeTime(new Date());

    return isEqual(this.agencyStatus?.referenceDate, today);
  }

  get areReferenceDateAndCurrentDayDivergent() {
    if (!this.agencyStatus) {
      return;
    }
    const { currentDay, referenceDate } = this.agencyStatus;

    return !isEqual(removeTime(referenceDate), removeTime(currentDay));
  }

  get exportActioName(): string {
    return get(this.agencyStatus, [
      'agencyDetails',
      'allowedActions',
      0,
      'action',
    ]);
  }
}
