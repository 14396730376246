export enum AgencyIds {
  osservatorioTuristico = 1,
  ross1000Turisno5 = 2,
  c59 = 3,
  vitValleDAosta = 5,
  spotPuglia = 6,
  sardegna = 7,
  radarLazio = 9,
  stuTrentino = 12,
  ricestat = 13,
  campaniaOsservatorioTuristicoNazionale = 14,
  rimovcli = 15,
}
