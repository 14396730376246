import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import {
  AgencyDetails,
  IIstatGuestsCard,
  IstatReportingGuestsData,
  SendToExternalRequest,
} from '../../models';
import { BuildAlertData } from '../../models/objects/build-alert-data';
import { Hooks } from '../../helpers';

export const setSendToExternalRequest = createAction(
  '[Istat Guests Report] Set Send To External Request',
  props<{
    data: SendToExternalRequest;
  }>(),
);

export const setSendToExternalSuccess = createAction(
  '[Istat Guests Report] Set Send To External Success',
);

export const setSendToExternalFailure = createAction(
  '[Istat Guests Report] Set Send To External Failure',
  props<{ error: any }>(),
);

export const sendGuestsRequest = createAction(
  '[Istat Guests Report] Send Guests Request',
  props<{
    groupId: number;
    query?: string;
    params: {
      reference_date: string;
      date_from: string;
      date_to: string;
      action: string;
      checkin_only?: 1 | 0;
      from_date?: string;
      to_date?: string;
    };
    hooks: Hooks;
  }>(),
);

export const sendGuestsSuccess = createAction(
  '[Istat Guests Report] Send Guests Success',
  props<{ items: IIstatGuestsCard[]; pagination: IPagination }>(),
);

export const sendGuestsFailure = createAction(
  '[Istat Guests Report] Send Guests Failure',
  props<{ error: any }>(),
);

export const loadGuestsDataRequest = createAction(
  '[Istat Guests Report] Load Guests Data Request',
  props<{
    groupId: number;
    params: {
      reference_date: string;
      date_from: string;
      date_to: string;
      checkin_only: 1 | 0;
    };
  }>(),
);

export const loadGuestsDataSuccess = createAction(
  '[Istat Guests Report] Load Guests Data Success',
  props<IstatReportingGuestsData>(),
);

export const loadGuestsDataFailure = createAction(
  '[Istat Guests Report] Load Guests Data Failure',
  props<{ error: any }>(),
);

export const loadAgencySpecificationsRequest = createAction(
  '[Istat Guests Report] Load Agency Specifications Request',
  props<{ groupId: number }>(),
);

export const loadAgencySpecificationsSuccess = createAction(
  '[Istat Guests Report] Load Agency Specifications Success',
  props<{ agency: AgencyDetails }>(),
);

export const loadAgencySpecificationsFailure = createAction(
  '[Istat Guests Report] Load Agency Specifications Failure',
  props<{ error: any }>(),
);

export const setCustomFieldsRequest = createAction(
  '[Istat Guests Report] Set Custom Fields Request',
  props<{ groupId: number; fields }>(),
);

export const setCustomFieldsSuccess = createAction(
  '[Istat Guests Report] Set Custom Fields Success',
);

export const setCustomFieldsFailure = createAction(
  '[Istat Guests Report] Set Custom Fields Failure',
  props<{ error: any }>(),
);

export const setAlertSuccess = createAction(
  '[Istat Guests Report] Set Alert Succes',
  props<BuildAlertData>(),
);

export const deleteGuestSentRequest = createAction(
  '[Istat Guests Report] Delete Guest Sent Request',
  props<{
    groupId: number;
    params: {
      reference_date: string;
      date_from: string;
      date_to: string;
      action: string;
      guest_id: number;
    };
  }>(),
);

export const deleteGuestSentSuccess = createAction(
  '[Istat Guests Report] Delete Guest Sent Success',
  props<{ items: IIstatGuestsCard[] }>(),
);

export const deleteGuestSentFailure = createAction(
  '[Istat Guests Report] Delete Guest Sent Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Istat Guests Report] Reset State');
