import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { AgencyDetails, IstatReportingGuestsData } from '../../models';
import { IstatGuestsReportService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';
import { effectHooks } from '../../helpers';

@Injectable()
export class IstatGuestsReportStoreEffects {
  constructor(
    private dataService: IstatGuestsReportService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAgencySpecificationsRequest),
      switchMap((payload) =>
        this.dataService.loadAgencySpecifications(payload.groupId).pipe(
          map((response: IResponseSuccess<AgencyDetails>) => {
            return fromActions.loadAgencySpecificationsSuccess({
              agency: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadAgencySpecificationsFailure(error));
          }),
        ),
      ),
    ),
  );

  sendGuests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendGuestsRequest),
      switchMap((payload) => {
        const { params, groupId, hooks } = payload;
        return this.dataService.send(groupId, params).pipe(
          effectHooks(hooks),
          switchMap((response: any) => {
            let errors = [];
            Object.keys(response.data).forEach((key) => {
              errors = [
                ...errors,
                ...(get(response.data, [key, 'errors'], []) as {
                  message: string;
                }[]),
              ];
            });

            if (errors.length) {
              errors.forEach((error: { message: string }) => {
                this.notifications.push({
                  title: this.translate.instant('error'),
                  content: this.translate.instant(error.message),
                  type: 'error',
                });
              });
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.generic_success',
                ),
                type: 'success',
              });
            }
            const actions = [
              fromActions.sendGuestsSuccess({
                items: response.data,
                pagination: response.pagination,
              }),
              fromActions.loadAgencySpecificationsRequest({
                groupId,
              }),
            ];
            return actions;
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.sendGuestsFailure(error));
          }),
        );
      }),
    ),
  );

  deleteGuestSent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteGuestSentRequest),
      switchMap(({ params, groupId }) =>
        this.dataService.deleteGuestSent(groupId, params).pipe(
          mergeMap((response) => {
            const deletions = response.data.deletions;

            if (deletions?.errors?.length) {
              deletions.errors.forEach((error: { message: string }) => {
                this.notifications.push({
                  title: this.translate.instant('error'),
                  content: error.message,
                  type: 'error',
                });
                return [
                  fromActions.deleteGuestSentFailure({
                    error: error.message,
                  }),
                ];
              });
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('guest'),
                  },
                ),
                type: 'success',
              });

              return [
                fromActions.deleteGuestSentSuccess({
                  items: deletions.data,
                }),
                fromActions.loadAgencySpecificationsRequest({
                  groupId,
                }),
              ];
            }
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteGuestSentFailure(error));
          }),
        ),
      ),
    ),
  );

  loadGuestData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadGuestsDataRequest),
      switchMap((payload) => {
        const { params, groupId } = payload;
        return this.dataService.load(groupId, params).pipe(
          map((response: IResponseSuccess<IstatReportingGuestsData>) =>
            fromActions.loadGuestsDataSuccess({
              entries: response.data.entries,
              statuses: response.data.statuses,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadGuestsDataFailure(error));
          }),
        );
      }),
    ),
  );

  setCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCustomFieldsRequest),
      switchMap((payload) => {
        const { fields, groupId } = payload;
        return this.dataService.setCustomFields(groupId, fields).pipe(
          mergeMap(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.generic_success'),
              type: 'success',
            });
            return [
              fromActions.loadAgencySpecificationsRequest({ groupId }),
              fromActions.setCustomFieldsSuccess(),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setCustomFieldsFailure(error));
          }),
        );
      }),
    ),
  );

  sendToExternal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSendToExternalRequest),
      switchMap((payload) =>
        this.dataService.sendToExternal(payload.data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.generic_success'),
              type: 'success',
            });
            return fromActions.setSendToExternalSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setSendToExternalFailure(error));
          }),
        ),
      ),
    ),
  );
}
