import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { get, isEqual } from 'lodash';

import { formatDate, removeTime } from '../../../../helpers';
import { IstatPageAlerts } from '../../../../models';
import { BuildAlertData } from '../../../../models/objects/build-alert-data';

import { AgencyService } from './agency.service';

@Injectable({ providedIn: 'root' })
export class AbitSondrioService extends AgencyService {
  agencyStatus: Partial<BuildAlertData>;

  showSendButton = false;

  showExportButton = true;

  showNextButton = true;

  showCloseButton = false;

  get isExportDisabled(): boolean {
    return (
      this.showSendingLimitYesterdayAlert ||
      this.showNoReservationCheckinCheckoutAlert ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get isNextDisabled(): boolean {
    return (
      this.showSendingLimitYesterdayAlert ||
      !this.showNoReservationCheckinCheckoutAlert ||
      this.areReferenceDateAndCurrentDayDivergent
    );
  }

  get isSendDisabled(): boolean {
    return false;
  }

  get isCloseDisabled(): boolean {
    return false;
  }

  get areReferenceDateAndCurrentDayDivergent() {
    if (!this.agencyStatus) {
      return;
    }
    const { currentDay, referenceDate } = this.agencyStatus;

    return !isEqual(removeTime(referenceDate), removeTime(currentDay));
  }

  get showNoReservationCheckinCheckoutAlert(): boolean {
    if (!this.agencyStatus) {
      return;
    }

    const { allGuestsSent, atLeastOneGuestNeedsUpdate, reservationsTotal } =
      this.agencyStatus;

    return (
      reservationsTotal === 0 || (allGuestsSent && !atLeastOneGuestNeedsUpdate)
    );
  }

  get showSendingLimitYesterdayAlert() {
    if (!this.agencyStatus) {
      return;
    }

    const today = removeTime(new Date());

    return isEqual(this.agencyStatus?.referenceDate, today);
  }

  buildAlerts(): IstatPageAlerts[] {
    const last_sent_day = get(
      this.agencyStatus?.agencyDetails?.customs,
      'last_sent_day',
    );

    let alerts: IstatPageAlerts[] = [];

    if (!this.showNoReservationCheckinCheckoutAlert) {
      alerts = [
        ...alerts,
        {
          messages: [
            'istat_agency_alerts.arrivals_or_departures_to_be_deliver',
          ],
          priority: 1,
          type: 'info',
          params: {
            action: 'to_export',
          },
        },
      ];
    }

    if (this.showNoReservationCheckinCheckoutAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.no_reservation_checkin_checkout'],
          priority: 2,
          type: 'info',
          params: {
            action_phrase: 'data_of_export',
            action: 'to_export',
          },
        },
      ];
    }

    if (this.showSendingLimitYesterdayAlert) {
      alerts = [
        ...alerts,
        {
          messages: ['istat_agency_alerts.action_limit_yesterday'],
          params: { action: 'export_1' },
          priority: 4,
          type: 'info',
        },
      ];
    }

    if (this.areReferenceDateAndCurrentDayDivergent) {
      alerts = [
        ...alerts,
        {
          messages: [
            'istat_agency_alerts.reference_date_is_divergent_current_date',
          ],
          params: {
            current_day: formatDate(
              this.agencyStatus?.agencyDetails?.customs?.current_day,
            ),
            action_phrase: 'data_of_export',
          },
          priority: 5,
          type: 'info',
        },
      ];
    }

    alerts.sort(
      ({ priority: priorityA }, { priority: priorityB }) =>
        priorityB - priorityA,
    );
    if (alerts.length) {
      let alert = alerts[0];

      if (last_sent_day) {
        alert = this.addLastExportedFileMessage(alert, last_sent_day);
      }
      return [alert];
    }
    return [];
  }

  addLastExportedFileMessage(
    alert: IstatPageAlerts,
    last_sent_day: string,
  ): IstatPageAlerts {
    return {
      ...alert,
      messages: [
        'istat_agency_alerts.last_exported_file_message',
        ...alert.messages,
      ],
      params: {
        ...alert.params,
        exportDate: format(new Date(last_sent_day), 'dd/MM/yyyy'),
      },
    };
  }
  get exportActioName(): string {
    return get(this.agencyStatus, [
      'agencyDetails',
      'allowedActions',
      0,
      'action',
    ]);
  }
}
