import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  AgencyDetails,
  Entries,
  IstatPageAlerts,
  Statuses,
} from '../../models';

export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<any> {
  isLoading?: boolean;
  entriesLoading: boolean;
  error?: any;
  agencySpecifications: AgencyDetails;
  entries: Entries;
  statuses: Statuses;
  allGuestsSent: boolean;
  alerts: IstatPageAlerts[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  entriesLoading: true,
  error: null,
  agencySpecifications: null,
  entries: {},
  allGuestsSent: false,
  alerts: [],
  statuses: {
    dates: [],
    guest: [],
  },
});
