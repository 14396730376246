import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  AgencyDetails,
  Entries,
  IstatPageAlerts,
  StatusesDate,
} from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getIsEntriesLoading = (state: State) => state.entriesLoading;

export const getEntries = (state: State): Entries => state.entries;

export const getStatutesDates = (state: State): StatusesDate[] =>
  state.statuses.dates;

export const getAlerts = (state: State): IstatPageAlerts[] => state.alerts;

export const getAllGuestsSent = (state: State): boolean => state.allGuestsSent;

export const getAgencySpecifications = (state: State): AgencyDetails =>
  state.agencySpecifications;

export const selectState = createFeatureSelector<State>('istatGuestsReport');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectIsEntriesLoading = createSelector(
  selectState,
  getIsEntriesLoading,
);

export const selectAgencySpecifications = createSelector(
  selectState,
  getAgencySpecifications,
);

export const selectAlerts = createSelector(selectState, getAlerts);

export const selectEntries = createSelector(selectState, getEntries);

export const selectStatusesDate = createSelector(selectState, getStatutesDates);

export const selectAllGuestsSent = createSelector(
  selectState,
  getAllGuestsSent,
);
