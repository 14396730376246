import { Injectable, Injector } from '@angular/core';

import { AgencyIds } from '../constant/agency-ids';

import { AgencyService } from './agency.service';
import { C59Service } from './c59.service';
import { CampaniaOsservatorioTuristicoNazionaleService } from './campania-rilevatore-turistico-nazionale.service';
import { OsservatorioTuristicoService } from './osservatorio-turistico.service';
import { RadarLazioService } from './radar-lazio.service';
import { RicestatService } from './ricestat.service';
import { RimovcliService } from './rimovcli.service';
import { Ross1000Turismo5Service } from './ross1000–turismo_5.service';
import { SardegnaService } from './sardegna.service';
import { SpotPugliaService } from './spot-puglia.service';
import { StuTrentino } from './stu-trentino.service';
import { TurismaticaUmbriaService } from './turismatica-umbria.service';
import { VitValleDAostaService } from './vit-valle-d-aosta.service';
import { WebturFriuliVeneziaGiuliaService } from './webtur-friuli-venezia-giulia';
import { AbitSondrioService } from './abit-sondrio';
import { AstatService } from './astat.service';
import { RadarLazioWebService } from './radar-lazio-web-service';

@Injectable({ providedIn: 'root' })
export class AgencyResolverService {
  agencyIds = AgencyIds;

  agencyServices = {
    'osservatorio-turistico-sicilia': OsservatorioTuristicoService,
    'turismo-5': Ross1000Turismo5Service,
    c59: C59Service,
    'hbb-vit': VitValleDAostaService,
    'hbb-puglia': SpotPugliaService,
    sired: SardegnaService,
    'hbb-lazio': RadarLazioService,
    trento: StuTrentino,
    ricestat: RicestatService,
    campania: CampaniaOsservatorioTuristicoNazionaleService,
    rimovcli: RimovcliService,
    webtur: WebturFriuliVeneziaGiuliaService,
    turismatica: TurismaticaUmbriaService,
    abit: AbitSondrioService,
    astat: AstatService,
    lazio: RadarLazioWebService,
  };

  constructor(private injector: Injector) {}

  getAgency(slug: string): AgencyService {
    return this.injector.get<AgencyService>(this.agencyServices[slug]);
  }
}
