import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createReducer, on } from '@ngrx/store';
import { get } from 'lodash';
import { SubSink } from 'subsink';

import { AgencyResolverService } from '../../features/commons/istat-guest-reporting/services/agency-resolver.service';
import { AgencyService } from '../../features/commons/istat-guest-reporting/services/agency.service';
import {
  buildIstatAccommodation,
  checkGuestsSendStatus,
  removeTime,
} from '../../helpers';
import { Entries } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

@Injectable()
export class IstatGuestReportReducer implements OnDestroy {
  agencyService: AgencyService;

  referenceDate: string;

  private subs = new SubSink();

  constructor(
    private agencyResolver: AgencyResolverService,
    private route: ActivatedRoute,
  ) {
    this.referenceDate = this.route.snapshot.queryParams?.reference_date;

    this.subs.add(
      this.route.queryParams.subscribe((data: any) => {
        if (!data.reference_date) {
          return;
        }
        this.referenceDate = get(data, 'reference_date');
      }),
    );
  }

  createReducer() {
    return createReducer(
      fromState.initialState,
      on(fromActions.loadGuestsDataRequest, (state) => ({
        ...state,
        isLoading: true,
        entriesLoading: true,
        error: null,
      })),
      on(
        fromActions.loadGuestsDataSuccess,
        (state, { entries, statuses: { guest: guestsStatus, dates } }) => {
          const reservationsEntries: Entries = entries.reduce(
            (acc, { property_id }) => {
              acc = {
                ...acc,
                [+property_id]: entries
                  .filter((entrie) => entrie.property_id === property_id)
                  .map((reservation) => {
                    const {
                      send_to_external,
                      reservation_id: reservationId,
                      accommodations,
                      reference_number,
                      departure_date,
                      arrival_date,
                      booker: { name: bookerName, surname: bookerSurname },
                    } = reservation;
                    const newAccommodations = buildIstatAccommodation(
                      accommodations,
                      guestsStatus,
                    );

                    return {
                      bookerName,
                      bookerSurname,
                      departure_date,
                      arrival_date,
                      reference_number,
                      send_to_external,
                      reservationId,
                      atLeastOneGuestSent: newAccommodations.some(
                        (accommodation) => accommodation.atLeastOneGuestSent,
                      ),
                      allGuestsSent: newAccommodations.every(
                        (accommodation) => accommodation.allGuestsSent,
                      ),
                      accommodations: newAccommodations,
                    };
                  }),
              };
              return acc;
            },
            {},
          );

          const reservations = Object.values(reservationsEntries).reduce(
            (acc, curr) => acc.concat(curr),
            [],
          );

          const { allGuestsSent, atLeastOneGuestNeedsUpdate } =
            checkGuestsSendStatus(reservations);

          let alerts = [];
          if (this.agencyService) {
            this.agencyService.agencyStatus = {
              agencyDetails: state.agencySpecifications,
              allGuestsSent,
              atLeastOneGuestNeedsUpdate,
              reservationsTotal: reservations.length,
              referenceDate: removeTime(new Date(this.referenceDate)),
              currentDay: removeTime(
                new Date(state.agencySpecifications?.customs?.current_day),
              ),
              isDayOpen: get(guestsStatus, '[0].send.isOpen'),
            };
            alerts = this.agencyService.buildAlerts();
          }
          return {
            ...state,
            isLoading: false,
            entriesLoading: false,
            error: null,
            entries: reservationsEntries,
            statuses: { dates, guest: guestsStatus },
            allGuestsSent,
            alerts,
          };
        },
      ),

      on(fromActions.loadGuestsDataFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),
      on(fromActions.loadAgencySpecificationsRequest, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      })),
      on(
        fromActions.loadAgencySpecificationsSuccess,
        (state, { agency: agencySpecifications }) => {
          this.agencyService = this.agencyResolver?.getAgency(
            agencySpecifications.agency.slug,
          );
          return {
            ...state,
            isLoading: false,
            error: null,
            agencySpecifications,
          };
        },
      ),
      on(fromActions.loadAgencySpecificationsFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),

      on(fromActions.sendGuestsRequest, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      })),
      on(fromActions.sendGuestsSuccess, (state) => ({
        ...state,
        isLoading: false,
        error: null,
      })),
      on(fromActions.sendGuestsFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),
      on(fromActions.setCustomFieldsSuccess, (state) => ({
        ...state,
        isLoading: false,
        error: null,
      })),
      on(fromActions.loadAgencySpecificationsFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),

      on(fromActions.setSendToExternalRequest, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      })),
      on(fromActions.setSendToExternalSuccess, (state) => ({
        ...state,
        isLoading: false,
        error: null,
      })),
      on(fromActions.setSendToExternalFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),

      on(fromActions.setAlertSuccess, (state, data) => {
        let alerts = [];
        if (this.agencyService) {
          this.agencyService.agencyStatus = data;
          alerts = this.agencyService.buildAlerts();
        }
        return {
          ...state,
          isLoading: false,
          error: null,
          allGuestsSent: data.allGuestsSent,
          alerts,
        };
      }),

      on(fromActions.deleteGuestSentRequest, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      })),
      on(fromActions.deleteGuestSentSuccess, (state) => ({
        ...state,
        isLoading: false,
        error: null,
      })),
      on(fromActions.deleteGuestSentFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),

      on(fromActions.resetState, () => fromState.initialState),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

export function istatGuestsReportReducerFactory(
  reducer: IstatGuestReportReducer,
) {
  return reducer.createReducer();
}
